<template>
  <TransitionRoot appear :show="props.modelValue" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-[9999999]">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform rounded-2xl bg-white p-6 text-left align-middle transition-all max-h-[500px] bg-transparent"
            >
              <div class="mt-3 flex justify-center items-center relative">
                <input
                  v-model="search"
                  @keypress="globalSearch"
                  class="block w-full appearance-none rounded-md border border-untitled-gray-300 px-3 py-2 placeholder-untitled-gray-400 shadow-sm focus:border-untitled-gray-500 focus:outline-none focus:ring-untitled-gray-500 text-xl"
                  type="text"
                  placeholder="Start typing to search..."
                />
                <span
                  v-if="search?.length"
                  class="absolute right-0 mr-2 text-untitled-gray-500 hover:cursor-pointer"
                  >Enter</span
                >
              </div>
              <div v-if="isLoading" class="mt-3 p-10 bg-white text-center">
                <LoadingState />
              </div>
              <div v-else>
                <div
                  class="mt-3 px-[10px] max-h-[300px] overflow-y-auto bg-white"
                  v-if="isSearching && !isLoading && search?.length"
                >
                  <div class="mt-1 border-t-gray-500 pt-3 pb-3">
                    <h3 class="text-lg text-untitled-gray-500">Users</h3>
                    <ul>
                      <li
                        class="mt-2 text-sm border-b-2 border-b-untitled-gray-200 hover:cursor-pointer hover:bg-slate-200 p-1"
                        v-for="u in users"
                        :key="u.id"
                      >
                        <a
                          class="flex flex-row items-center text-untitled-gray-500 justify-between w-100"
                          @click.prevent="impersonate(u.id)"
                        >
                          <small>{{ u.name }}</small>
                          <UserIcon
                            class="h-4 w-4 text-untitled-gray-400 hover:cursor-pointer"
                          />
                        </a>
                      </li>
                      <li v-if="users.length === 0">No user(s) found</li>
                      <li v-if="users?.length > 5" class="mt-3 font-bold text-sm">
                        <small>Press enter for more results</small>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script setup>
import { TransitionRoot, TransitionChild, Dialog, DialogPanel } from "@headlessui/vue";
import { ref, inject, defineProps, defineEmits } from "vue";
import { useCookies } from "vue3-cookies";
import { useAuth } from "@websanova/vue-auth/src/v3.js";
import { useAuthStore } from "@/stores";
import { debounce as _debounce } from "lodash-es";
import Bugsnag from "@bugsnag/js";
import LoadingState from "@/components/partials/LoadingState.vue";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    default: false,
    type: Boolean,
  },
});

const axios = inject("axios");
const { cookies } = useCookies();
const auth = useAuth();
const authStore = useAuthStore();
const search = ref("");
const isSearching = ref(false);
const isLoading = ref(false);
const users = ref([]);

const closeModal = () => {
  emit("update:modelValue", false);
};

const impersonate = (id) => {
  axios
    .post(`/api/impersonate-user/${id}`)
    .then(({ data }) => {
      localStorage.setItem("impersonate_user", JSON.stringify(data.user));
      localStorage.setItem("impersonate_token", JSON.stringify(data.token));

      cookies.set("default_auth_token", data.token);
      cookies.set("default_auth_user", data.user.id);
      auth.user(data.user);
      auth.token(null, data.token, false);
      authStore.$patch({
        user: data.user,
        token: data.token,
      });

      window.location.reload();
    })
    .catch((error) => {
      isLoading.value = false;
      Bugsnag.notify(error);
    });
};

const globalSearch = _debounce(() => {
  isLoading.value = true;
  isSearching.value = true;
  axios
    .get("/api/search-user", {
      params: {
        search_query: search.value,
      },
    })
    .then(({ data }) => {
      users.value = data;
      console.log(data);
      isLoading.value = false;
    })
    .catch((error) => {
      isLoading.value = false;
      Bugsnag.notify(error);
    });
}, 1000);
</script>
